<template>
  <v-card>
    <v-card-title class="justify-center text-center">
      Número de Vehículos por Calle en el Día
    </v-card-title>

    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        Error en el servicio {{ error }}
      </v-alert>
      <div
        v-if="!loading && !error"
        class="small"
      >
        <bar-chart
          v-if="datacollection"
          :chart-data="datacollection"
        ></bar-chart>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import BarChart from '../../charts/BarChart'

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      datacollection: null,
      loading: true,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    fillData(data) {
      const chartData = []
      const chartLabels = []
      data.map(value => {
        chartData.push(value.total)
        chartLabels.push(value.calle)
        this.loading = false

        return true
      }, {})
      this.datacollection = {
        labels: chartLabels,
        datasets: [
          {
            label: 'Calle',
            backgroundColor: '#028675',
            data: chartData,
          },
        ],
      }
      this.error = false
      this.loading = false
    },
    setLoading() {
      this.loading = true
      this.error = false
    },
    setError(error) {
      this.loading = false
      this.error = error
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Educación</span>
    </v-card-title>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>

        <v-skeleton-loader
          class="mx-auto"
          type="article"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        {{ errMessage }}
      </v-alert>

      <v-row v-if="!loading && !error" justify="center">
        <v-col cols="6" class="d-flex justify-center">
          <bar-chart
            v-if="chartDataAnalfabetismo"
            :chart-data="chartDataAnalfabetismo"
            :is-dark="isDark"
            :isPercentage="true"/>
        </v-col>
        <v-col cols="6" class="d-flex justify-center">
          <bar-chart v-if="chartDataEscolaridad" :chart-data="chartDataEscolaridad" :is-dark="isDark"></bar-chart>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div class="mt-3">
        <p class="text-right font-weight-bold">
          Fuente información: Elaboración propia basada en INEGI, CONAPO y proyecciones da datos utilizando modelos fundacionales de inteligencia artificial.
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import poblacion from '@/services/poblacion'
import BarChart from '../../charts/BarChart'

export default {
  name: 'TpEducacion',
  components: {
    BarChart,
  },
  data() {
    return {
      data: {},
      loading: true,
      error: false,
      errMessage: '',
      mostRecentYear: null,
      chartDataAnalfabetismo: null,
      chartDataEscolaridad: null,
      isDark: false,
    }
  },
  watch: {
    '$vuetify.theme.dark': function handleThemeChange(newVal) {
      this.isDark = newVal
    },
  },
  mounted() {
    this.isDark = this.$vuetify.theme.dark
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const resp = await poblacion.getEducacion()
        const { data } = resp

        const transformedData = {
          Anio: [],
          Analfabetismo: [],
          Escolaridad: [],
        }

        data.rows.forEach(row => {
          row.cells.forEach(cell => {
            const value = cell.displayValue.replace(',', '.').replace('%', '')
            switch (cell.columnId) {
              case 1456351925718916:
                transformedData.Anio.push(cell.displayValue)
                break
              case 7378962576789380:
                transformedData.Analfabetismo.push(parseFloat(value))
                break
              case 5959951553089412:
                transformedData.Escolaridad.push(parseFloat(value))
                break
              default:
                break
            }
          })
        })

        const mostRecentYear = Math.max(...transformedData.Anio.map(year => parseInt(year, 10)))

        this.data = transformedData
        this.mostRecentYear = mostRecentYear
        this.loading = false
        this.prepareChartData()
      } catch (err) {
        this.error = err.response.data
        this.loading = false
      }
    },
    prepareChartData() {
      this.chartDataAnalfabetismo = {
        labels: this.data.Anio.map(anio => `${anio}`),
        datasets: [
          {
            label: 'Porcentaje de Analfabetismo',
            backgroundColor: '#f79c17',
            data: this.data.Analfabetismo,
          },
        ],
      }
      this.chartDataEscolaridad = {
        labels: this.data.Anio.map(anio => `${anio}`),
        datasets: [
          {
            label: 'Grado Promedio de Escolaridad',
            backgroundColor: '#5bc0de',
            data: this.data.Escolaridad,
          },
        ],
      }
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Salud ({{ mostRecentYear }})</span>
    </v-card-title>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>

        <v-skeleton-loader
          class="mx-auto"
          type="article,article,article"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        {{ errMessage }}
      </v-alert>

      <v-row v-if="!loading && !error">
        <v-col cols="12">
          <div class="progress-container mx-auto mt-4">
            <div class="progress-label">
              Población Afiliada
            </div>
            <v-progress-linear
              v-if="poblacionAfiliadaValue !== null"
              :value="poblacionAfiliadaValue"
              color="primary"
              height="30"
              class="mx-auto"
            >
              <template v-slot:default="{ value }">
                <div :class="['text-center', 'bold-text', { 'white-text': isDark }]">
                  {{ value }}%
                </div>
              </template>
            </v-progress-linear>
          </div>
        </v-col>
        <v-col cols="12">
          <bar-chart
            v-if="chartDataSalud"
            :chart-data="chartDataSalud"
            :is-dark="isDark"
            :options="chartOptions"
            :isPercentage="true"/>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div>
        <p class="text-right mt-3 font-weight-bold">
          Fuente información: Elaboración propia basada en INEGI y proyecciones de datos utilizando modelos fundacionales de inteligencia artificial.
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import poblacion from '@/services/poblacion'
import BarChart from '../../charts/BarChart'

export default {
  name: 'TpSalud',
  components: {
    BarChart,
  },
  data() {
    return {
      data: {},
      loading: true,
      error: false,
      errMessage: '',
      mostRecentYear: null,
      chartDataSalud: null,
      chartDataPoblacionAfiliada: null,
      poblacionAfiliadaValue: null,
      isDark: false,
      chartOptions: {
        legend: {
          display: false,
        },
      },
    }
  },
  watch: {
    '$vuetify.theme.dark': function handleThemeChange(newVal) {
      this.isDark = newVal
    },
  },
  mounted() {
    this.isDark = this.$vuetify.theme.dark
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const resp = await poblacion.getSalud()
        const { data } = resp

        const transformedData = {
          labels: [],
          datasets: [{
            backgroundColor: ['#50ac2c', '#2da6df', '#f79c17', '#a25a94', '#1a93a7', '#028675'],
            data: [],
          }],
        }

        const poblacionAfiliadaData = {
          labels: [],
          datasets: [{
            label: 'Población Afiliada',
            backgroundColor: '#028675',
            data: [],
          }],
        }

        data.columns.forEach(column => {
          if (column.id !== 859820059217796 && column.id !== 6179038371729284) { // Excluir la columna de año y Población Afiliada
            transformedData.labels.push(column.title)
          }
        })

        data.rows.forEach(row => {
          const year = row.cells.find(cell => cell.columnId === 859820059217796).displayValue
          const poblacionAfiliada = row.cells.find(cell => cell.columnId === 6179038371729284).displayValue

          poblacionAfiliadaData.labels.push(year)
          poblacionAfiliadaData.datasets[0].data.push(parseFloat(poblacionAfiliada.replace(',', '.')))

          row.cells.forEach(cell => {
            if (cell.columnId !== 859820059217796 && cell.columnId !== 6179038371729284) { // Excluir la columna de año y Población Afiliada
              transformedData.datasets[0].data.push(parseFloat(cell.displayValue.replace(',', '.')))
            }
          })
        })

        // Calcular el año más reciente
        const mostRecentYear = Math.max(...poblacionAfiliadaData.labels.map(year => parseInt(year, 10)))

        // Calcular el valor de Población Afiliada
        const poblacionAfiliadaValue = poblacionAfiliadaData.datasets[0].data.reduce((a, b) => a + b, 0) / poblacionAfiliadaData.datasets[0].data.length

        this.data = transformedData
        this.poblacionAfiliadaData = poblacionAfiliadaData
        this.mostRecentYear = mostRecentYear
        this.poblacionAfiliadaValue = poblacionAfiliadaValue // Asignar el valor calculado
        this.loading = false
        this.chartDataSalud = transformedData
        this.chartDataPoblacionAfiliada = poblacionAfiliadaData
      } catch (err) {
        this.error = err.response.data
        this.loading = false
      }
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
}
</script>

<style scoped>
.progress-container {
  position: relative;
  text-align: center;
  width: 100%;
}

.progress-label {
  position: absolute;
  top: -20px;
  left: 0;
  font-weight: bold;
}

.bold-text {
  font-weight: bold;
  color: #333333;
}

.white-text {
  color: #c9c4df;
}
</style>

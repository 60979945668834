<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Población</span>
    </v-card-title>
    <v-card-text>
      <v-row v-if="loading">
        <v-col cols="12" sm="12" md="6">
          <v-progress-linear :indeterminate="true"></v-progress-linear>
          <v-sheet :color="`grey ${isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader class="mx-auto" type="article"></v-skeleton-loader>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-sheet :color="`grey ${isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader class="mx-auto" type="article"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>

      <v-alert v-if="error"
               outlined
               type="error"
               transition="scale-transition"
      >
        Error en el servicio {{ error }}
      </v-alert>

      <v-row v-if="!loading && !error">
        <v-col cols="12" sm="12" md="6">
          <bar-chart
            v-if="chartDataPoblacion"
            :chart-data="chartDataPoblacion"
            :is-dark="isDark"
            :isPercentage="false"/>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <bar-chart
            v-if="chartDataActiva"
            :chart-data="chartDataActiva"
            :is-dark="isDark"
            :isPercentage="false"/>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <bar-chart
            v-if="chartDataEmpleoMujeres"
            :chart-data="chartDataEmpleoMujeres"
            :is-dark="isDark"
            :isPercentage="false"/>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <bar-chart
            v-if="chartDataSeguroSocial"
            :chart-data="chartDataSeguroSocial"
            :is-dark="isDark"
            :isPercentage="false"/>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div>
        <p class="text-right mt-3 font-weight-bold">
          Fuente información: Elaboración propia basada en INEGI, CONAPO y proyecciones da datos utilizando modelos fundacionales de inteligencia artificial.
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import poblacion from '@/services/poblacion'
import BarChart from '../../charts/BarChart'

export default {
  name: 'TpPoblacion',
  components: {
    BarChart,
  },
  data() {
    return {
      pdata: {},
      loading: true,
      error: false,
      errMessage: '',
      chartData: null,
      mostRecentYear: null,
      isDark: false,
    }
  },
  watch: {
    '$vuetify.theme.dark': function handleThemeChange(newVal) {
      this.isDark = newVal
    },
  },
  mounted() {
    this.isDark = this.$vuetify.theme.dark
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const resp = await poblacion.getPoblacion()
        const { data } = resp

        // Transformar los datos recibidos
        const transformedData = {
          PoblacionTotal: { Total: [], Anio: [] },
          Hombres: { Total: [], Anio: [] },
          Mujeres: { Total: [], Anio: [] },
          SeguroSocial: { Total: [], Anio: [] },
          Activa: { Total: [], Anio: [] },
          EmpleoMujeres: { Total: [], Anio: [] },
        }

        data.rows.forEach(row => {
          row.cells.forEach(cell => {
            switch (cell.columnId) {
              case 5885344749932420:
                transformedData.PoblacionTotal.Anio.push(cell.displayValue)
                transformedData.Hombres.Anio.push(cell.displayValue)
                transformedData.Mujeres.Anio.push(cell.displayValue)
                transformedData.SeguroSocial.Anio.push(cell.displayValue)
                transformedData.Activa.Anio.push(cell.displayValue)
                transformedData.EmpleoMujeres.Anio.push(cell.displayValue)
                break
              case 8137144563617668:
                transformedData.PoblacionTotal.Total.push(cell.displayValue)
                break
              case 818795169140612:
                transformedData.Hombres.Total.push(cell.displayValue)
                break
              case 5322394796511108:
                transformedData.Mujeres.Total.push(cell.displayValue)
                break
              case 3070594982825860:
                transformedData.SeguroSocial.Total.push(cell.displayValue)
                break
              case 6650229570097028:
                transformedData.Activa.Total.push(cell.displayValue)
                break
              case 4398429756411780:
                transformedData.EmpleoMujeres.Total.push(cell.displayValue)
                break
              default:
                break
            }
          })
        })

        // Calcular el año más reciente
        const allYears = [
          ...transformedData.PoblacionTotal.Anio,
          ...transformedData.Hombres.Anio,
          ...transformedData.Mujeres.Anio,
          ...transformedData.SeguroSocial.Anio,
          ...transformedData.Activa.Anio,
          ...transformedData.EmpleoMujeres.Anio,
        ]
        const mostRecentYear = Math.max(...allYears.map(year => parseInt(year, 10)))

        this.pdata = transformedData
        this.mostRecentYear = mostRecentYear
        this.loading = false
        this.prepareChartData()
      } catch (err) {
        this.error = err.response.data
        this.loading = false
      }
    },
    prepareChartData() {
      this.chartDataPoblacion = {
        labels: this.pdata.PoblacionTotal.Anio.map(anio => `${anio}`),
        datasets: [
          {
            label: 'Población total',
            backgroundColor: '#1a93a7',
            data: this.pdata.PoblacionTotal.Total,
          },
          {
            label: 'Población masculina',
            backgroundColor: '#028675',
            data: this.pdata.Hombres.Total,
          },
          {
            label: 'Población femenina',
            backgroundColor: '#a25a94',
            data: this.pdata.Mujeres.Total,
          },
        ],
      }
      this.chartDataSeguroSocial = {
        labels: this.pdata.SeguroSocial.Anio.map(anio => `${anio}`),
        datasets: [
          {
            label: 'Población con seguro social',
            backgroundColor: '#2da6df',
            data: this.pdata.SeguroSocial.Total,
          },
        ],
      }
      this.chartDataActiva = {
        labels: this.pdata.Activa.Anio.map(anio => `${anio}`),
        datasets: [
          {
            label: 'Población económicamente activa',
            backgroundColor: '#50ac2c',
            data: this.pdata.Activa.Total,
          },
        ],
      }
      this.chartDataEmpleoMujeres = {
        labels: this.pdata.EmpleoMujeres.Anio.map(anio => `${anio}`),
        datasets: [
          {
            label: 'Empleo mujeres en lo turístico e industrial',
            backgroundColor: '#f79c17',
            data: this.pdata.EmpleoMujeres.Total,
          },
        ],
      }
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-title class="align-start">
      <p class="text-lg-center">
        <span class="font-weight-semibold">Infraestructura</span>
      </p>
    </v-card-title>

    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <div>
        <v-alert
          v-if="error"
          outlined
          type="error"
          transition="scale-transition"
        >
          Error en el servicio {{ error }}
        </v-alert>
        <mapa-camaras
          v-show="!loading && !error"
          ref="map"
          api-key="AIzaSyBMAYXqqQ4ryrasH_5O5WkkrdFW6zH4cq4"
        ></mapa-camaras>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import MapaCamaras from './MapaCamaras'
import poblacion from '@/services/poblacion'

export default {
  components: {
    MapaCamaras,
  },
  props: {
    datos: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: true,
      error: false,
    }
  },
  mounted() {
    this.getData()
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    getData() {
      poblacion
        .getUbicacionCamaras()
        .then(resp => {
          this.$refs.map.createMap(resp.data)
          this.loading = false
        })
        .catch(err => {
          this.error = err
          this.loading = false
        })
    },
    setLoading() {
      this.loading = true
      this.error = false
    },
    setError(error) {
      this.loading = false
      this.error = error.response.data
    },
  },
}
</script>
